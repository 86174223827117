import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
export const StatsTable = ({statsdata}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const formattedData = statsdata.map(item => ({
      Date: item.dimension,
      IMPS: item.impressions,
      ECPM: item.cpm,
      Revenue: item.amount,
      click: item.clicks,
    }));

    setData(formattedData);
  }, [statsdata]);

  const columns = [
    {
      name: 'Date',
      selector: (row) => row.Date,
      sortable: true,
    },
    {
      name: 'IMPS',
      selector: (row) => row.IMPS,
      sortable: true,
    },
    {
      name: 'ECPM',
      selector: (row) => row.ECPM,
      sortable: true,
    },
    {
      name: 'Revenue',
      selector: (row) => row.Revenue,
      sortable: true,
    },
    {
      name: 'Click',
      selector: (row) => row.click,
      sortable: true,
    },
  ];
  

  const redirect = (id) => {
    console.log(`Redirecting to /website/${id}`);
  };

  return (
    <div className="formBox statschart">
      <p className="title">Total {data.length} items</p>
      <DataTable
        columns={columns}
        data={data}
        pagination
        title="Reports"
        style={{
          fontSize: '16px',
          border: '1px solid #ddd',
          borderRadius: '5px',
          margin: '10px',
          maxHeight: '300px',
          overflowY: 'auto',
        }}
        noHeader
        striped
        highlightOnHover
        pointerOnHover
        onRowClicked={(row) => {
          redirect(row.id);
        }}
        customStyles={{
          headRow: {
            style: {},
          },
          headCells: {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingLeft: '16px',
              paddingRight: '16px',
            },
            sortableStyle: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingLeft: '16px',
              paddingRight: '16px',
            },
            activeSortStyle: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingLeft: '16px',
              paddingRight: '16px',
            },
            inactiveSortStyle: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingLeft: '16px',
              paddingRight: '16px',
            },
            sortIconStyle: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
          },
          rows: {
            style: {},
          },
        }}
      />
    </div>
  );
};
