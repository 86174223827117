// Sidebar.js
import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom/dist";
import { CiHome } from "react-icons/ci";
import { FaArrowTrendUp } from "react-icons/fa6";
import { IoIosGlobe } from "react-icons/io";
import { BsWallet } from "react-icons/bs";
import { PiUsersFill } from "react-icons/pi";
import { LuUser2 } from "react-icons/lu";
import { IoMdLogOut } from "react-icons/io";
import { FiCode } from "react-icons/fi";
const Sidebar = () => {
    useEffect(() => {
        // add hovered class to selected list item
         document.querySelectorAll(".navigation li");

        // Menu Toggle
        let toggle = document.querySelector(".toggle");
        let navigation = document.querySelector(".navigation");
        let main = document.querySelector(".main");

        toggle.onclick = function () {
            navigation.classList.toggle("active");
            toggle.classList.toggle("change");
            main.classList.toggle("active");
        };
    }, []);
    return (
        <div className="tt-container">
            <div className="navigation">
                <ul className='ps-0'>
                    <li>
                        <a href="/">
                            <span className="icon">
                                {/* <ion-icon name="logo-apple"></ion-icon> */}

                            </span>
                            <span className="title">Brand Name</span>
                        </a>
                    </li>

                    <li>
                        <NavLink to='/'>
                            <span className="icon">
                                <CiHome size={20} />
                            </span>
                            <span className="title">Dashboard</span>
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to='/statistics' >
                            <span className="icon">
                                {/* <ion-icon name="people-outline"></ion-icon> */}
                                <FaArrowTrendUp size={20} />
                            </span>
                            <span className="title">Statistics</span>
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to='/websites'>
                            <span className="icon">
                                < IoIosGlobe size={20} />

                            </span>
                            <span className="title">Websites</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/code-gen'>
                            <span className="icon">
                                < FiCode size={20} />

                            </span>
                            <span className="title">Code Generator</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/billing'>
                            <span className="icon">
                                <BsWallet size={20} />
                            </span>
                            <div className="dropdown">
                        {/* <button className="dropbtn"> */}
                        <span className="title dropbtn">Billing</span>
                        {/* </button> */}
                        {/* <div className="dropdown-content ">
                            <div className="tt-dropdown-content billing_dropdown">
                                <div className="tt-dropdown-triangle"></div>
                                <Link className='billing_link ' to="/billing/payouts">Payout</Link>
                                <Link className='billing_link'  to="/billing/transactions">Transactions</Link>
                               
                            </div>
                        </div> */}
                    </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/referrals">
                            <span className="icon">
                                <PiUsersFill size={20} />
                            </span>
                            <span className="title">Referrals</span>
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to='/profile'>
                            <span className="icon">
                                <LuUser2 size={20} />
                            </span>
                            <span className="title">Profle</span>
                        </NavLink>
                    </li>

                    <li>
                        <a href="/">
                            <span className="icon">
                                <IoMdLogOut size={20} />
                            </span>
                            <span className="title">Sign Out</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;
