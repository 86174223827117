import React, { useEffect, useState } from "react";
import { FiCode } from "react-icons/fi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { IoClipboardOutline } from "react-icons/io5";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";

const CodeGen = () => {
  const [AllSiteData, setAllSiteData] = useState([]);
  const [Allzones, setAllzones] = useState([]);
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const [normalTag, setNormalTag] = useState([]);
  const [compactTag, setCompactTag] = useState([]);
  const [showNormalTag, setShowNormalTag] = useState(true); // Track whether to display normalTag or compactTag
const [generating, setgenerating] = useState(false)
  const htmlString = '&lt;/body&gt;';

  const copytext = () => {
    setshowModal(true);
    setTimeout(() => {
      setshowModal(false);
    }, 1000);
  };

  const fetchAllWebsites = async () => {
    try {
      const params = {
        page: 1,
        sort: 'AUS',
        'per-page': 100,
        id: JSON.parse(localStorage.getItem('user'))
      };
      const response = await axios.get('https://popupexamplebackend.dev-ewdtech.org/site/getAllSites', {
        params: params,
      });
      setAllSiteData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSelectChange = async (event) => {
    try {
      setSelectedWebsite(event.target.value);
      const idsite = event.target.value;
      const page = 1;
      const perpage = 100;
      const sort = 'AUS';
      const response = await axios.post('https://popupexamplebackend.dev-ewdtech.org/zone/getzoneslist', { idsite, page, perpage, sort });
      setAllzones(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const generateCode = async (selectedValue) => {
    setgenerating(true)
    try {
      const zoneid = selectedValue;
      const response = await axios.post('https://popupexamplebackend.dev-ewdtech.org/zone/getSpecificZone', { zoneid });
      setNormalTag(response.data.data.code[0].code);
      setCompactTag(response.data.data.code[1].code);
    } catch (error) {
      console.log(error);
    }
    setgenerating(false)
  };

  useEffect(() => {
    fetchAllWebsites();
  }, []);

  return (
    <div>
      <h3 style={{marginTop:'20px'}}>Generate Code</h3>
      <div className="formBox code_box gen_code">
        <p className="title">Select Website</p>
        <div className="code_form">
          <select
            className="form__input"
            name="idcategory"
            onChange={handleSelectChange}
            // value={selectedWebsite}
            defaultValue=""
          >
            <option   value="" disabled>Select Website</option>
            {AllSiteData.map((website) => (
              <option key={website.id} value={website.id}>
                {website.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {Allzones.length > 0 ? (
  <div className="formBox code_box gen_code">
    <p className="title">Select Zone</p>
    <div className="code_form">
      <select className="form__input" name="idcategory" onChange={handleSelectChange} value={selectedWebsite}>
        <option value="" disabled>Select Website</option>
        {Allzones.map((zone) => (
          <option key={zone.id} value={zone.id}>
            {zone.name}
          </option>
        ))}
      </select>
      <button disabled={generating} className="blue_btn" onClick={() => generateCode(selectedWebsite)}>
        <FiCode size={24} />
       {generating? "Generating Code":"Generate Code"} 
      </button>
    </div>
  </div>
) : (
  <p className="mt-4" style={{ marginLeft: '17px' }}>
    {selectedWebsite ? (
      <span>No zones are available: <a href="/create-zone">create zone</a></span>
    ) : (
      <span>No website is selected</span>
    )}
  </p>
)}




      <div className="flex-container">
        <div className="formBox column-60 code_box">
        <div className="pt-3">
        <label>
          <input type="radio" checked={showNormalTag} onChange={() => setShowNormalTag(!showNormalTag)} />
          &nbsp;Normal Tag
        </label>
        <label className="ps-3">
          <input type="radio" checked={!showNormalTag} onChange={() => setShowNormalTag(!showNormalTag)} />
          &nbsp;    Compact Tag
        </label>
      </div>
          <div className="title">Your Embeddable Code</div>
          <div className="code_form">
            <input
              className="form__input"
              name="idcategory"
              value={showNormalTag ? normalTag : compactTag}
              disabled
            />
            <CopyToClipboard onCopy={copytext} text={showNormalTag ? normalTag : compactTag}>
              <button className="blue_btn">
                <IoClipboardOutline size={24} />
                Copy
              </button>
            </CopyToClipboard>
          </div>
        </div>

        <div className="formBox column-40 code_box">
          <div style={{ alignItems: 'start' }} className="guide_head code_form">
            <h4 className="">Instructions to integrate the Code.</h4>
            <AiOutlineQuestionCircle size={24} />
          </div>
          <div className="guide_subHead">
            The code must be inserted right before the <b> <div dangerouslySetInnerHTML={{ __html: htmlString }} /></b> closing tag to work properly.
          </div>
          <div className="guide_subHead">
            Please insert the JavaScript code only on the registered domain; otherwise, your impressions will not be counted.
          </div>
          <Link className="blue_btn" to="/statistics">
            See all reports
          </Link>
        </div>
      </div>

      

      {showModal && <Alert icon={<IoClipboardOutline size={24} />} text="Text copied to clipboard..!!" />}
    </div>
  );
};

export default CodeGen;

const Alert = ({ icon, text }) => {
  return (
    <>
      <div className="cm-container">
        <div className="cm-content">
          <div className="clipboard-icon">{icon}</div>
          {text}
        </div>
      </div>
      <div className="overlay"></div>
    </>
  );
};
