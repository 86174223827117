import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import logo from "../../components/assets/images/logo.svg";
import illustration from "../../components/assets/images/illustration.svg";
import axios from 'axios'
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const validationSchema = yup.object({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const redirect = (link) => {
    navigate(link);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async(data) => {
const response =  await axios.post('https://popupexamplebackend.dev-ewdtech.org/users/login',

{data}) 
if(response.data.status === 1){
  localStorage.setItem('user', JSON.stringify(response.data.user.id));
  Swal.fire({icon:"success", text:"Logged in "})
navigate('/')


}
else{
  Swal.fire({icon:"error", text: response.data || "Failed to logged in "})
 
}

  };

  return (
    <div className="signup">
      {/* left section */}
      <div className="singup_inner">
      <div className="left_section">
          <div className="logo">
            <img className="logo_Img" src={logo} alt="something" />
            <span className="logo_Name">
              Pop <b>Example</b>
            </span>{" "}
          </div>
          <div className="signup_inner ill">
            <div className="illustration_Container">
              <div className="illustration">
                <img src={illustration} alt="something" />
              </div>
              <p className="left_heading"> A few more clicks to <br /> sign up to your account.</p>
              <p className="left_subHeading">Manage all your e-commerce accounts in one place </p>
            </div>
          </div>
        </div>

        {/* right section  */}
        <div className="signup_inner">
          <form onSubmit={handleSubmit(onSubmit)} className="signup_form ">
            <p className="signup_title title_margins">Sign In</p>
            <div className="formText ">
              <p>
                {" "}
                A few more clicks to sign up to your account. Manage all your
                e-commerce accounts in one place{" "}
              </p>
            </div>
            <div className="form-content">
              <div className="form-field">
                <input
                  type="text"
                  className="form__input singup_input"
                  placeholder="Email"
                  {...register("email")}
                />
                {errors.email && (
                  <p className="error-message">{errors.email.message}</p>
                )}
              </div>

              <div className="form-field">
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form__input singup_input"
                    placeholder="Password"
                    {...register("password")}
                  />
                  <span onClick={() => setShowPassword(!showPassword)}>
                    {/* {showPassword ? "👁️" : "👁️"} */}
                  </span>
                </div>
                {errors.password && (
                  <p className="error-message">{errors.password.message}</p>
                )}
              </div>

              <div
                style={{
                  marginRight: 0,
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
                className="form_btns signup_btns"
              >
                {/* Add your Cancel button here */}
                <div className="form-checkbox">
                  <input type="checkbox" {...register("idrole")} />
                  <label>Remember Me</label>
                </div>
                <div className="form-checkbox">
                  <Link to="/password-reset">Forgot Password</Link>
                </div>
              </div>
            </div>

            <div className="form_btns signup_btns">
             
              <button type="submit" className="white_btn signup_btn">
                Sign In
              </button>
              <button
                onClick={() => {
                  redirect("/signup");
                }}
                type="btn"
                className="blue_btn signup_btn"
              >
                Register
              </button>
             
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
