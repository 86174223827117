import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { useForm } from "react-hook-form";
const Notifications = () => {
  const [notifications, setNotifications] = useState([]);

  const fetchAllNotifications = async () => {
    try {
      const response = await axios.get('https://popupexamplebackend.dev-ewdtech.org/notifications'); 
      setNotifications(response.data.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  const {
    register,
    handleSubmit
  } = useForm();

  const onSubmit = async (data) => {
    const websiteStatusChanged = data.websiteStatusChanged || false;
    const zoneStatusChanged = data.zoneStatusChanged || false;
    try {
      const response = await axios.post('https://popupexamplebackend.dev-ewdtech.org//update-notification-pref', {
        websiteStatusChanged,
        zoneStatusChanged,
      });
      console.log('Update Notification Preferences Result:', response.data);
    } catch (error) {
      console.error('Error updating notification preferences:', error);
    }
  };
  useEffect(() => {
    fetchAllNotifications();
  }, []);
  useEffect(() => {
    const dummyNotifications = Array.from({ length: 5 }, (_, index) => ({
      id: index + 1,
      content: `Dummy Notification ${index + 1}`,
      publishedAt: new Date().toISOString(),
    }));

    setNotifications(dummyNotifications);
  }, []);
  const data = notifications.map((notification) => ({
    id: notification.id,
    content: notification.content,
    publishedAt: notification.publishedAt,
  }));

  const markAsRead = (id) => {
    // Implement the logic to mark the notification as read
    console.log(`Mark as read clicked for notification with id ${id}`);
  };

  const columns = [
    {
      name: 'Notification',
      selector: 'content',
      sortable: true,
    },
    {
      name: 'Published At',
      selector: 'publishedAt',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <button onClick={() => markAsRead(row.id)} className='blue_btn notif_bell'>
            Mark as Read
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
        <h5 className=" title_margins ">Notification preferences</h5>
        {/* preference section */}
        <form onSubmit={handleSubmit(onSubmit)} className="formBox notif_form ">
         <p className=' '>Send an email notification in case of these events:</p>
          <div className="form-checkbox">
            <input
              type="checkbox"
              id="websiteStatusChanged"
              {...register('websiteStatusChanged')}
            />
            <label htmlFor="websiteStatusChanged">Website Status Changed</label>
          </div>

          <div className="form-checkbox">
            <input
              type="checkbox"
              id="zoneStatusChanged"
              {...register('zoneStatusChanged')}
            />
            <label htmlFor="zoneStatusChanged">Zone Status Changed</label>
          </div>

          <div className="form_btns signup_btns">
         
            <button className='white_btn '   type="submit">
           Update
            </button>
          </div>
        </form>

        {/* notifications list  */}
      <div className='web_heading'>
        <h5 className='title_margin'>Notifications</h5>
      </div>
      <DataTable
        columns={columns}
        data={data}
        pagination
        style={{
          fontSize: '16px',
          border: '1px solid #ddd',
          borderRadius: '5px',
          margin: '10px',
        }}
        noHeader
        striped
        highlightOnHove
        pointerOnHover
        onRowClicked={(row) => {
          // You can add additional actions on row click
          console.log('Row clicked:', row);
        }}
        customStyles={{
          headRow: {
            style: {
              background: '#f5f5f5',
            },
          },
          headCells: {
            style: {},
          },
          rows: {
            style: {},
          },
        }}
      />
    </div>
  );
};

export default Notifications;
