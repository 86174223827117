import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import { StatsTable } from './StatsTable';
import StatsChart from './StatsChart';
import { DatePicker } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from 'react-router-dom';
import * as yup from "yup";
import Swal from 'sweetalert2';
const { RangePicker } = DatePicker;
const schema = yup.object().shape({
  group: yup.string().required('Group is required'),
});
const Statistics = () => {
  const [showChart, setShowChart] = useState(true);
  const [statisticsdata, setStatisticsData] = useState(null);
  const [AllSiteData, setAllSiteData] = useState(null);
  const [selectedDate, setSelectedDate] = useState({});
  const [generating, setgenerating] = useState(false)
    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idsite = queryParams.get('idsite');
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues:{idsite:idsite}
    
  });


  const handleDateChange = (dates, dateStrings) => {
    const dateBegin = dates[0].format('YYYY-MM-DD');
    const dateEnd = dates[1].format('YYYY-MM-DD');
    setSelectedDate({ dateBegin, dateEnd });

  };
  const toggleChartVisibility = () => {
    setShowChart(!showChart)
  }

  const fetchStastics = async ({idsite}) => {
    try {
      const endDate = new Date().toISOString().split('T')[0];
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 30);
      const startDateFormatted = startDate.toISOString().split('T')[0];
      const response = await axios.post('https://popupexamplebackend.dev-ewdtech.org/statistis/getAPI',
        {
          dateBegin: startDateFormatted, idsite, dateEnd: endDate, group: 'day', group2: null, idpublisher: JSON.parse(localStorage.getItem('user'))
        })
      // console.log(response.data.data)
      if(response.data.data){
      setStatisticsData(response.data.data)}
      else{
        Swal.fire({icon:"error", text:"An error occured while generating report"})
      }
    }
    catch (error) {
      console.log(error)
    }
  }
  const getStatsWithConditions = async (data) => {
    // console.log(data);
    setgenerating(true)
    try {
      const response = await axios.post('https://popupexamplebackend.dev-ewdtech.org/statistis/getAPI',
      {
        dateBegin: selectedDate.dateBegin,
        dateEnd: selectedDate.dateEnd || null,
        group: data.group,
        group2: data.group2 > 0 ? data.group2 : null,
        idsite:data.idsite > 0 ? data.idsite : null,
        idpublisher: JSON.parse(localStorage.getItem('user')),
      }
      
        )
        if(response.data.data){
          setStatisticsData(response.data.data)}
          else{
            Swal.fire({icon:"error", text:"An error occured while generating report"})
            
          }
          setgenerating(false)
        
    }
    catch (error) {
      console.log(error)
    }
  }
  const fetchAllWebsites = async () => {

    try {
      const params = {
        page: 1,
        sort: 'AUS',
        'per-page': 100,
        id: JSON.parse(localStorage.getItem('user'))
      };
      const response = await axios.get('https://popupexamplebackend.dev-ewdtech.org/site/getAllSites', {
        params: params,
      });
      setAllSiteData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    // fetchStastics();
    fetchAllWebsites();
    // getdictionary()
    if(idsite){
      fetchStastics({idsite})
    }
  }, [idsite]);

  return (
    <>
      <div className=''>
        <h3 className=" title_margins ">Statistics</h3>
        {/*stats conditions start*/}
        <div className=' formBox'>
          <form onSubmit={handleSubmit(getStatsWithConditions)} >
            <div className="stats_condition">
              {/* first group */}
              <div className="form-field">
                <label className='form__label'>Group By</label>
                <select
                defaultValue="day"
                  className="form__input"
                  name="group"
                  {...register("group")}
                >
                  <option value="day" >Day</option>
                  <option value="month" >Month</option>
                  <option value="year" >Year</option>

                </select>
                {errors.group && <p className="error-message">{errors.group.message}</p>}
              </div>
              {/* second group */}
              <div className="form-field">
                <label className='form__label'>Group By (extra) </label>
                <select
                  className="form__input"
                  name="group"
                  {...register("group1")}
                  defaultValue=""
                >
                  <option value="" ></option>
                  <option value="day" >Day</option>
                  <option value="month" >Month</option>
                  <option value="year" >Year</option>

                </select>
                {errors.group && <p className="error-message">{errors.group.message}</p>}
              </div>
              {/* website */}
              <div className="form-field">
                <label className='form__label'>Select Website</label>
                <select
                defaultValue=''
                  className="form__input"
                  name="idsite"
                  {...register("idsite")}
                >
                  <option value=""  ></option>
                  {AllSiteData && AllSiteData.map((website) => (
                    <option selected={idsite && website.id===idsite} key={website.id} value={website.id}>
                      {website.name}
                    </option>
                  ))}
                </select>
                {errors.idcategory && <p className="error-message">{errors.idcategory.message}</p>}
              </div>
              <div className="form-field">
                <label className='form__label'>Date Range <span className='error'>*</span> </label>
                {" "}
                <RangePicker
                required
                aria-required={true}
                  style={{ marginRight: '10px' }}
                  defaultValue={[selectedDate.dateBegin, selectedDate.dateEnd]}
                  format="YYYY-MM-DD"
                  onChange={handleDateChange}
                />{" "}
                  {/* {(!selectedDate.dateBegin  || !selectedDate.dateEnd) && <p className="error-message">Date range is required</p>} */}
              </div>
            
            
            </div>

            <div style={{ flexGrow: 2 }} className="form_btns">
              <button
              disabled={!selectedDate.dateBegin  || !selectedDate.dateEnd || generating}
                type="submit"
                className="white_btn"
              >
                {!generating? "Generate Report":"Generating Report"}
                
              </button>
              <CSVLink className="blue_btn" data={statisticsdata || ''} disabled={!statisticsdata} filename="report.csv">
                Download
              </CSVLink>
            </div>
          </form>
   </div>

      </div>
      {
        statisticsdata ?<>
          <StatsTable statsdata={statisticsdata} />
          <StatsChart chartdata={statisticsdata} showChart={showChart} toggleChartVisibility={toggleChartVisibility} title='Chart' chartDatashow={statisticsdata} />
        </> :<p className='no_stats'>Select Some options to get the stats</p>
      }



    </>
  );
};

export default Statistics;
