import React, { useRef, useState } from "react";
import "./referrals.css";
import totalrefers from "../assets/images/total-refers.PNG";
import revenuerefers from "../assets/images/revenue-refer.PNG";
import activerefers from "../assets/images/active-refers.PNG";
import block_160_600 from "../assets/images/160X600.PNG";
import CopyReferrals from "./CopyReferrals.jsx";
const Referrals = () => {
  const inputRef = useRef(null);
  const referrals300X250 = '<a href="https://www.rocoads.net"><img src="https://rocoads.com/300-X-250.gif" border="0"></a>'
  const referrals700X90 = '<a href="userreferrallink"><img src="https://rocoads.com/728-X-90.gif" border="0"></a>'
  const [inputValue] = useState(
    '<a href="userreferrallink"><img src="https://rocoads.com/728-X-90.gif" border="0"></a>'
  );
  const [copytext, setCopytext] = useState("Copy");

  const copyText = (event) => {
    // Select the text in the input
    inputRef.current.select();
    inputRef.current.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text to the clipboard
    document.execCommand("copy");
    setCopytext("Copied");
    // Deselect the input
    inputRef.current.setSelectionRange(0, 0);
  };
  return (
    <React.Fragment>
      <div className="tt-shadow p-4 bg-white rounded mt-4">
        {/* < ------------------------------ > */}

        <h5 className="mb-3 px-2">Referral Stats</h5>
        <div className="row mt-3">
          <div className="col-lg-4">
            <div className="tt-inner-shadow p-3 rounded-3">
              <div className="tt-referals-img-h">
                <img src={totalrefers} alt="" />
              </div>
              <h2 className="mb-0 py-2 fw-bolder">0</h2>
              <p className="mb-0">Total Refferals</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="tt-inner-shadow  p-3 rounded-3">
              <div className="tt-referals-img-h">
                <img src={revenuerefers} alt="" />
              </div>
              <h2 className="mb-0 py-2 fw-bolder">0</h2>
              <p className="mb-0">Active Refferals</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="tt-inner-shadow p-3 rounded-3">
              <div className="tt-referals-img-h">
                <img src={activerefers} alt="" />
              </div>
              <h2 className="mb-0 py-2 fw-bolder">$0.00</h2>
              <p className="mb-0">Revenue Refferals</p>
            </div>
          </div>
        </div>
        {/* < ------------------------------ > */}

        {/* < ------------------------------ > */}
        <div className="mt-5">
          <div className="row">
            <div className="col-lg-6">
              <div className="tt-inner-shadow p-4 rounded-3">
                <h5 className="mb-3">Referral link</h5>
                <div className="d-flex">
                  <div className="col-lg-10">
                    <div className="tt-copy-text">
                      <input
                        type="text"
                        className="px-3 py-3 w-100"
                        ref={inputRef}
                        value={inputValue}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="ps-2 w-100">
                    <button
                      className="tt-copy-button w-100 h-100"
                      onClick={copyText}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-clipboard"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                      </svg>{" "}
                      {copytext}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tt-inner-shadow p-4 h-100 rounded-3">
                Promote us and win money. <br />
                You will earn 10% of referred publishers revenue lifetime Active
                referrals are all the refferals that send at at least 10 visits
                to our system.
              </div>
            </div>
          </div>
        </div>
        {/* < ------------------------------ > */}

        {/* < ------------------------------ > */}
        <div className="mt-5">
          <div className="row">
            <div className="col-lg-9">
              <div className="tt-inner-shadow p-4 rounded-3 h-50">
                <h5 className="mb-3 px-2">Referral List</h5>
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3">
                          ID
                        </th>
                        <th scope="col" className="py-3">
                          User Name
                        </th>
                        <th scope="col" className="py-3">
                          Date
                        </th>
                        <th scope="col" className="py-3">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="4">
                          You don't have any referral active yet.
                        </td>
                      </tr>
                    </tbody>
                    <tbody style={{ display: "none" }}>
                      <tr>
                        <th>1</th>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                      </tr>
                      <tr>
                        <th>2</th>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <th>3</th>
                        <td>Larry the Bird</td>
                        <td>@twitter</td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="pt-3 h-50 ">
                <div className="tt-inner-shadow  rounded-3 h-100 d-flex flex-column align-items-center justify-content-center w-100">
                  <div className="text-end pt-5 w-100">
                    {" "}
                    <img
                      src="https://rocoads.com/728-X-90.gif"
                      className="mb-3"
                      alt=""
                    />
                  </div>
                  <div className="p-4 text-center w-100">
                    <CopyReferrals referalLinks={referrals700X90} height="82px" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="tt-inner-shadow p-4 rounded-3 text-center">
                <img src={block_160_600} alt=""  />
                <div className="pt-3">
                  <CopyReferrals referalLinks={referrals700X90} height="160px" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* < ------------------------------ > */}

        {/* < ------------------------------ > */}
        <div className="mt-5">
          <div className="row">
            <div className="col-lg-3">
              <div className="tt-inner-shadow p-4 rounded-3">
                <img
                  src="https://rocoads.com/300-X-250.gif"
                  className="w-100"
                  alt=""
                />
                <div className="pt-3 text-center">
                  <CopyReferrals referalLinks={referrals300X250} height="160px" />
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="tt-inner-shadow p-4 text-center h-100 d-flex flex-column align-items-center justify-content-center w-100 rounded-3">
                <img src="https://rocoads.com/728-X-90.gif" alt=""  />
                <div className="pt-3 text-center w-100">
                  <CopyReferrals referalLinks={referrals700X90} height="68px" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* < ------------------------------ > */}
      </div>
    </React.Fragment>
  );
};

export default Referrals;
