import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { IoIosArrowDown } from "react-icons/io";

const StatsChart = ({ chartdata, showChart, toggleChartVisibility }) => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Impressions',
        type: 'bar',
        data: [],
      },
      {
        name: 'Revenue',
        type: 'bar',
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          }},
          fontFamily: '"Poppins", sans-serif',
      },
      // ... rest of your options
    },
  });

  useEffect(() => {
    const categories = chartdata.map((d) => d.dimension);
    const Impressions = chartdata.map((item) => item.impressions);
    const Revenue = chartdata.map((item) => parseFloat(item.amount));

    setChartData((prevChartData) => ({
      ...prevChartData,
      series: [
        {
          ...prevChartData.series[0],
          data: Impressions,
        },
        {
          ...prevChartData.series[1],
          data: Revenue,
        },
      ],
      options: {
        ...prevChartData.options,
        xaxis: {
          categories,
        },
      },
    }));
  }, [chartdata]);

  return (
    <div className={`chart ${showChart ? "chartMax" : "chartMin"} formBox`} id="chart">
      <div className='chart_header'>
        <h5 className='title'>Chart</h5>
        <button className={`chart_toggle ${!showChart ? "angle90" : "angle0"}`} onClick={toggleChartVisibility}>
          <IoIosArrowDown className={`${!showChart ? "toggleiconlg" : "toggleicon"}`} />
        </button>
      </div>
      {showChart && <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={400} />}
    </div>
  );
};

export default StatsChart;
