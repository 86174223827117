import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  is_active: yup.boolean().required('Please indicate if the website is active'),
  idzoneformat: yup.string().required('zone format is required'),
});

export const CreateZone = ({ setActiveComponent }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const idsite = urlParams.get('idsite');
  const navigate = useNavigate()
  const redirect = (link) => {
    navigate(link)
  }

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const createzon = async (data) => {
    const is_active = data.is_active ? 1 : 0;
    const iddimension = 28;
    const idstatus = 7010;
   data = { ...data, idsite, is_active, iddimension, idstatus }
    try {
      const responsesite = await axios.post('https://popupexamplebackend.dev-ewdtech.org/zone/create', data)
      if(responsesite.data.status===1){
        Swal.fire({
          icon: "success",
          text: "Zone Created Successfully!",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `
          }
        });
        navigate('/websites/zones')
      }else{
        Swal.fire({
          icon: "error",
          text: "Failed to Create Zone. Try Again!",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutUp
              animate__faster
            `
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error.message,
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutUp
            animate__faster
          `
        }
      });
    }
  };

  return (
    <div className=''>
      <p className='title title_margins'>Create New Zone</p>
      <form onSubmit={handleSubmit(createzon)} className="formBox form">
        <div className="form-content">
          <div className="form-field">
            <label className='form__label'>Name</label>
            <input
              type="text"
              className="form__input"
              placeholder="Name"
              name="name"
              {...register("name")}
            />
            {errors.name && <p className="error-message">{errors.name.message}</p>}
          </div>

          <div className="form-field">
            <label className='form__label'>Format</label>
            <select
              className="form__input"
              name="idzoneformat"
              {...register("idzoneformat")}
            >
              <option value={30}>
                Popup
              </option>
            </select>
            {errors.idzoneformat && <p className="error-message">{errors.idzoneformat.message}</p>}
          </div>
          <div className="form-checkbox">
            <input
              type="checkbox"
              className=""
            //   checked={state.is_active}
              {...register("is_active")}
            />
            <label>Is Active</label>
          </div>

          {errors.is_active && <p className="error-message">{errors.is_active.message}</p>}
        </div>

        <div className="form_btns">
          <button
            type="button"
            className="white_btn"
            onClick={() => redirect('/websites')}
          >
            Cancel
          </button>
          <button type='submit' className="blue_btn">Create Zone</button>
        </div>
      </form>
    </div>
  );
};
