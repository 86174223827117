import { useEffect, useState } from 'react';

export const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(localStorage.getItem('user'));

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    setCurrentUser(storedUser);
  }, []);

  const isAuthenticated = !!currentUser;

  return {
    isAuthenticated,
    currentUser,
  };
};
