import '../src/components/assets/css/dashboard.css'
import '../src/components/assets/css/main.css'
import '../src/components/assets/css/responsive.css'
import { Navigate } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Sidebar from './components/sidebar/Sidebar';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Websites from './components/websites/Websites';
import { CreateWebsite } from './components/websites/CreateWebsite';
import WebsiteDetails from './components/websites/WebsiteDetails';
import Statistics from './components/statistics/Statistics';
import Billing from './components/billing/Payout';
import Profile from './components/profile/Profile';
import Signup from './components/Register/Signup';
import SignIn from './components/Register/SignIn';
import Home from './components/dashboard/Home';
import CodeGen from './components/codeGen/CodeGen';
import Referrals from './components/referrals/Referrals.jsx';
import { useAuth } from './components/Register/Auth.js';
import Notifications from './components/notifications/Notifications.jsx';
import ResetPassword from './components/Register/ResetPassword.jsx';
import { CreateZone } from './components/websites/CreateZone.jsx';
import  UpdateWebsite  from './components/websites/UpdateWebsite.jsx';
import Zones from './components/websites/Zones.jsx';
function App() {
  return (
    <>
      <Router>
        <Routes>

          <Route path="/websites" element={<Dashboard CurrentComponent={<Websites />} />} />
          <Route path="/websites/create" element={<Dashboard CurrentComponent={<CreateWebsite />} />} />
          <Route path="/websites/update" element={<Dashboard CurrentComponent={<UpdateWebsite />} />} />
          <Route path="/websites/details" element={<Dashboard CurrentComponent={<WebsiteDetails />} />} />
          <Route path="/websites/zones" element={<Dashboard CurrentComponent={<Zones />} />} />
          <Route path="/statistics" element={<Dashboard CurrentComponent={<Statistics />} />} />
          <Route path="/billing" element={<Dashboard CurrentComponent={<Billing />} />} />
           <Route path="/profile" element={<Dashboard CurrentComponent={<Profile />} />} />
          <Route path="/" element={<Dashboard CurrentComponent={<Home />} />} />
          <Route path="/code-gen" element={<Dashboard CurrentComponent={<CodeGen />} />} />
          <Route path="/referrals" element={<Dashboard CurrentComponent={<Referrals />} />} />
          <Route path="/notifications" element={<Dashboard CurrentComponent={<Notifications />} />} />
          <Route path="/create-zone" element={<Dashboard CurrentComponent={<CreateZone />} />} />
          <Route  path='/password-reset' element={<ResetPassword />}/>
          <Route  path='/signup' element={<Signup />}/>
          <Route  path='/login' element={<SignIn />}/>
        </Routes> 
      </Router>
    </>
  );
}

export default App;

const Dashboard = ({ CurrentComponent }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <Sidebar />
      <div className='main'>
        <div className='inner_main'>
          <Navbar />
          {CurrentComponent}
        </div>
      </div>
    </div>
  );
};
