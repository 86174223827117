import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import Loader from "../dashboard/Loader";
import Swal from "sweetalert2";

const Profile = () => {
  const [generalingodata, setgeneralingoData] = useState(null);
const [loading, setloading] = useState(false)
  const getuserdata = async () => {
    setloading(false)
    try {
      const userId = JSON.parse(localStorage.getItem("user"));
      console.log(userId);
      const getuserdataresponse = await axios.get(
        "https://popupexamplebackend.dev-ewdtech.org/users/get_user_profile",
        {
          params: {
            id: userId,
          },
        }
      );
      setgeneralingoData(getuserdataresponse.data.data);
      setloading(false)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getuserdata();
  }, []);
  const saveGeneralInfo = async(data) => {
    console.log("Submitted General Info:", data);
    try {
      const userId = JSON.parse(localStorage.getItem('user'));
      const response = await axios.post('https://popupexamplebackend.dev-ewdtech.org/users//update_userProfile/billing',
          {data : {
            
            lName: data.lastName,
            fName: data.firstName,
            email: data.email,
            skype: data.skype,
          }, userId
          }
      );
      console.log(response);
  } catch (error) {
      console.log(error);
  }
  };

  const saveAddressInfo = async (data) => {
    console.log("Submitted Address Info:", data);
    try {
        const userId = JSON.parse(localStorage.getItem('user'));
        await axios.post('https://popupexamplebackend.dev-ewdtech.org/users//update_userProfile/billing',
            {data : {
              
              addressLine2: data.addressLine2,
              addressLine1: data.addressLine1,
              countryAddress: data.countryAddress,
              company: data.company,
            }, userId
            }
        );
    } catch (error) {
        console.log(error);
    }
};


  const changePassword = async (data) => {
    const userId = JSON.parse(localStorage.getItem("user"));
    const response = await axios.put(
      "https://popupexamplebackend.dev-ewdtech.org/users/updatePassword",
      {
        current_password: data.current_password,
        password: data.password,
        id: userId,
      }
    );
    if(response.status===1){
      Swal.fire({
        icon: "success",
        text: "Password Updated Successfully!",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
        }
      });
    }else{
      Swal.fire({
        icon: "error",
        text: "Failed to Update Password. Try Again!",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutUp
            animate__faster
          `
        }
      });
    }
  };
  return (
    <div className="billing_container">
    {!loading ?<> <div className="formBox">
        <p className="title">General Information</p>

        <GeneralInfoForm
          generalingodata={generalingodata}
          onSubmit={saveGeneralInfo}
        />
      </div>

      <div className=" profile_btm ">
        <AddressForm
          generalingodata={generalingodata}
          onSubmit={saveAddressInfo}
        />

        <ChangePasswordForm onSubmit={changePassword} />
      </div></> : <Loader />}
    </div>
  );
};

export default Profile;

const GeneralInfoForm = ({ onSubmit, generalingodata }) => {
  console.log(generalingodata);
  const {
    register,
    handleSubmit
  } = useForm({
    defaultValues: {
      firstName: generalingodata?.fName,
      lastName: generalingodata?.lName,
      email: generalingodata?.email,
      country: generalingodata,
      skype: generalingodata?.skype,
    },
  });

  return (
    <form
      style={{ marginLeft: "0px", paddingLeft: "0px" }}
      onSubmit={handleSubmit(onSubmit)}
      className="signup_form gen_info "
    >
      <div style={{ gap: 0 }} className="form-content">
        <div className="form-field">
          <label className="form__label">First Name</label>
          <input
            type="text"
            className="form__input info_input"
            placeholder="First Name"
            {...register("firstName")}
          />
        </div>

        <div className="form-field">
          <label className="form__label">Last Name</label>
          <input
            type="text"
            className="form__input info_input"
            placeholder="Last Name"
            {...register("lastName")}
          />
        </div>

        <div className="form-field">
          <label className="form__label">Email</label>
          <input
            type="text"
            className="form__input info_input"
            placeholder="Email"
            {...register("email")}
          />
        </div>
        {/* <div className="form-field">
              <label className="form__label">Country</label>
              <input
                type="text"
                className="form__input info_input"
                placeholder="country"
                {...register("country")}
              />
            </div> */}

        <div className="form-field">
          <label className="form__label">Skype</label>
          <input
            type="text"
            className="form__input info_input"
            placeholder="skype"
            {...register("skype")}
          />
        </div>
      </div>

      <div className="form_btns signup_btns">
        <button type="submit" className="blue_btn signup_btn">
          save
        </button>
      </div>
    </form>
  );
};

const AddressForm = ({ onSubmit, generalingodata }) => {
  const {
    register,
    handleSubmit
  } = useForm({
    defaultValues: {
      addressLine1: generalingodata?.addressLine1,
      addressLine2: generalingodata?.addressLine2,
      countryAddress: generalingodata?.countryAddress,
      company: generalingodata?.company,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="formBox grow1">
      <p className="title">Billing Address</p>
      <div className="form-field">
        <label className="form__label">Address Line 1</label>
        <input
          type="text"
          className="form__input info_input"
          placeholder="Address Line 1"
          {...register("lastName")}
        />
      </div>

      <div className="form-field">
        <label className="form__label">Address Line 2</label>
        <input
          type="text"
          className="form__input info_input"
          placeholder="Address Line 2"
          {...register("addressLine1")}
        />
      </div>
      <div className="form-field">
        <label className="form__label">Country</label>
        <input
          type="text"
          className="form__input info_input"
          placeholder="country"
          {...register("addressLine2")}
        />
      </div>

      <div className="form-field">
        <label className="form__label">Company</label>
        <input
          type="text"
          className="form__input info_input"
          placeholder="company"
          {...register("company")}
        />
      </div>

      <div className="form_btns signup_btns">
        <button type="submit" className="blue_btn signup_btn">
          Save
        </button>
      </div>
    </form>
  );
};

const ChangePasswordForm = ({ onSubmit }) => {
  const validationSchema = yup.object().shape({
    current_password: yup.string().required("Current Password is required"),
    password: yup.string().required("New Password is required"),
    cpassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <form className="formBox grow1" onSubmit={handleSubmit(onSubmit)}>
      <p className="title">Change Password</p>
      <div className="form-content">
        <div className="form-field">
          <label className="form__label">Current Password</label>
          <input
            type="password"
            className="form__input info_input"
            placeholder="Current Password"
            {...register("current_password")}
          />
          {errors.current_password && (
            <p className="error-message">{errors.current_password.message}</p>
          )}
        </div>

        <div className="form-field">
          <label className="form__label">New Password</label>
          <input
            type="password"
            className="form__input info_input"
            placeholder="New Password"
            {...register("password")}
          />
          {errors.password && (
            <p className="error-message">{errors.password.message}</p>
          )}
        </div>
        <div className="form-field">
          <label className="form__label">Confirm Password</label>
          <input
            type="password"
            className="form__input info_input"
            placeholder="Confirm Password"
            {...register("cpassword")}
          />
          {errors.cpassword && (
            <p className="error-message">{errors.cpassword.message}</p>
          )}
        </div>

        <div className="form_btns signup_btns">
          <button type="submit" className="blue_btn signup_btn">
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
