import React, { useState, useEffect } from "react";
import { BsWallet } from "react-icons/bs";
import { FiDollarSign } from "react-icons/fi";
import { BsCash } from "react-icons/bs";
import { DatePicker } from "antd";
import ReactApexChart from 'react-apexcharts';
import axios from "axios";
import Loader from "./Loader";

const { RangePicker } = DatePicker;

const Home = () => {
  const [statisticsdata, setStatisticsData] = useState(null);
  const [data, setData] = useState(null)
  const [loading, setloading] = useState(false)
  const [selectedDate, setSelectedDate] = useState({});
  const getThisMonth = async () => {
    setloading(true)
    try {
      const response = await axios.post('statistis/thismonth', {
        group: 'zone_format',
        group2: 'publisher',
        idpublisher: JSON.parse(localStorage.getItem('user'))
      });
      // setData((prevData) => ({ ...prevData, thisMonth: response.data.data[0] }));
      const { data } = response.data
      if (data && data.length > 0) {
        setData((prevData) => ({ ...prevData, thisMonth: response.data.data[0] }));
      }
      else {
        setData((prevData) => ({ ...prevData, thisMonth: { clicks: 0, amount: 0, impressions: 0 } }));
      }
      setloading(false)

    } catch (error) {
      console.log(error);
    }
  };

  // previous month
  const getPreviousMonth = async () => {
    setloading(true)
    try {
      const response = await axios.post('https://popupexamplebackend.dev-ewdtech.org/statistis/previousmonth', {
        group: 'zone_format',
        group2: 'publisher',
        idpublisher: JSON.parse(localStorage.getItem('user'))
      });
      const { data } = response.data
      if (data && data.length > 0) {
        setData((prevData) => ({ ...prevData, previousMonth: response.data.data[0] }));
      }
      else {
        setData((prevData) => ({ ...prevData, previousMonth: { clicks: 0, amount: 0, impressions: 0 } }));
      }
      setloading(false)

    } catch (error) {
      console.log(error);
    }
  };
  // data && console.log(data)
  // today
  const getToday = async () => {
    setloading(true)
    try {
      const response = await axios.post('https://popupexamplebackend.dev-ewdtech.org/statistis/today', {
        group: 'zone_format',
        group2: 'publisher',
        idpublisher: JSON.parse(localStorage.getItem('user'))
      });
      // setData((prevData) => ({ ...prevData, today: response.data.data[0] }));
      const { data } = response.data

      if (data && data.length > 0) {
        setData((prevData) => ({ ...prevData, today: response.data.data[0] }));
      }
      else {
        setData((prevData) => ({ ...prevData, today: { clicks: 0, amount: 0, impressions: 0 } }));
      }
      setloading(false)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getThisMonth()
    getPreviousMonth()
    getToday()
  }, [])


  const handleDateChange = (dates, dateStrings) => {
    const dateBegin = dates[0].format('YYYY-MM-DD');
    const dateEnd = dates[1].format('YYYY-MM-DD');
    setSelectedDate({ dateBegin, dateEnd });

  };
  useEffect(() => {
    const endDate = new Date().toISOString().split('T')[0];
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 10);
    const startDateFormatted = startDate.toISOString().split('T')[0];
    fetchStastics({ endDate, startDateFormatted })
  }, [])

  // fetching stats for previous 10 days (default chart )
  const fetchStastics = async ({ endDate, startDateFormatted }) => {
    try {
      console.log(endDate, startDateFormatted)
      const response = await axios.post('https://popupexamplebackend.dev-ewdtech.org/statistis/getAPI',
        {
          dateBegin: startDateFormatted, dateEnd: endDate, group: 'day', group2: null, idpublisher: JSON.parse(localStorage.getItem('user'))
        })
      setStatisticsData(response.data.data)
    }
    catch (error) {
      console.log(error)
    }
  }


  //  generating report for specific date
  const generateReport = async () => {
    console.log(selectedDate)
    console.log('hi')
    try {
      const response = await axios.post('https://popupexamplebackend.dev-ewdtech.org/statistis/getAPI',
        {
          dateBegin: selectedDate.dateBegin, dateEnd: selectedDate.dateEnd, group: 'day', group2: null, idpublisher: JSON.parse(localStorage.getItem('user'))
        })
    console.warn(response)
      setStatisticsData(response.data.data)
    }
    catch (error) {
      console.warn(error)
    }
  }


  return (
    <div>
      {/* cards */}
      {(!loading && data && data.today && data.previousMonth && data.today) ? <> <div className="cardBox">
        <div className="card home_cards">
          <div className="inner_card">
            <BsWallet style={{ color: '#f47c05' }} size={20} />
            <div className="numbers">{data.today?.amount}</div>
            <div className="cardName">Revenue Today, $ </div>
            <div className="Cards_shodow"></div>
          </div>
        </div>

        <div className="card home_cards">
          <div className="inner_card">
            <FiDollarSign style={{ color: 'blue' }} size={20} />
            <div className="numbers">{data.thisMonth?.amount}</div>
            <div className="cardName">Revenue this month, $ </div>
            <div className="Cards_shodow"></div>
          </div>
        </div>
        <div className="card home_cards">
          <div className="inner_card">
            <FiDollarSign style={{ color: 'blue' }} size={20} />
            <div className="numbers">{data.previousMonth?.amount}</div>
            <div className="cardName">Revenue Previous month, $ </div>
            <div className="Cards_shodow"></div>
          </div>
        </div>
        <div className="card home_cards">
          <div className="inner_card">
            <BsCash style={{ color: 'rgb(255 179 103)' }} size={20} />
            <div className="numbers">{data.today?.impressions}</div>
            <div className="cardName">Impressions today</div>
            <div className="Cards_shodow"></div>
          </div>
        </div>
        <div className="card home_cards">
          <div className="inner_card">
            <BsCash style={{ color: 'rgb(255 179 103)' }} size={20} />
            <div className="numbers">{data.thisMonth?.impressions}</div>
            <div className="cardName">Impressions this month</div>
            <div className="Cards_shodow"></div>
          </div>
        </div>
        <div className="card home_cards">
          <div className="inner_card">
            <BsCash style={{ color: 'rgb(255 179 103)' }} size={20} />
            <div className="numbers">{data.previousMonth?.impressions}</div>
            <div className="cardName">Impressions Previous month</div>
            <div className="Cards_shodow"></div>
          </div>
        </div>


        <div className="card home_cards">
          <div className="inner_card">
            <BsCash style={{ color: 'rgb(255 179 103)' }} size={20} />
            <div className="numbers">{data.today?.clicks}</div>
            <div className="cardName">Clicks today</div>
            <div className="Cards_shodow"></div>
          </div>
        </div>

        <div className="card home_cards">
          <div className="inner_card">
            <BsCash style={{ color: 'rgb(255 179 103)' }} size={20} />
            <div className="numbers">{data.thisMonth?.clicks}</div>
            <div className="cardName">Clicks this month</div>
            <div className="Cards_shodow"></div>
          </div>
        </div>
        <div className="card home_cards">
          <div className="inner_card">
            <BsCash style={{ color: 'rgb(255 179 103)' }} size={20} />
            <div className="numbers">{data.previousMonth?.clicks}</div>
            <div className="cardName">Clicks previous month</div>
            <div className="Cards_shodow"></div>
          </div>
        </div>


      </div>
        {/* chart */}
        <div className="report_header">
          <p className=" title">Report Filter</p>
          <div className="report_btns">
            <div>
              {" "}
              <RangePicker
                style={{ marginRight: '10px' }}
                defaultValue={[selectedDate.dateBegin, selectedDate.dateEnd]}
                format="YYYY-MM-DD"
                onChange={handleDateChange}
              />{" "}
            </div>
            <button onClick={generateReport} className="blue_btn ">Generate Report </button>
          </div>
          <div></div>
        </div>
        {statisticsdata && <DashboardChart data={statisticsdata} />}

      </> : <><Loader /></>}

    </div>
  );
};

export default Home;



// Dashboard chart 
const DashboardChart = ({ data }) => {
  const dates = data.map((d) => d.dimension);
  const impressions = data.map((d) => d.impressions);
  const revenue = data.map((d) => d.amount);
  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Revenue',
        data: [],
      },
      {
        name: 'Impressions',
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      xaxis: {
        categories: dates,
      },
      yaxis: [
        {
          seriesName: 'Revenue',
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#008FFB',
          },
          labels: {
            style: {
              colors: '#008FFB',
              fontWeight: 'normal',
              fontSize: '14px',
              fontFamily: '"Poppins", sans-serif',
            },
            formatter: function (value) {
              return Math.floor(value);
            },
          },
          title: {
            text: 'Revenue',
            style: {
              color: '#008FFB',
              fontWeight: 500,
            },
          },
        },
        {
          seriesName: 'Impressions',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#26e7a6',
          },
          labels: {
            style: {
              colors: '#26e7a6',
            },
            formatter: function (value) {
              return Math.floor(value);
            },
          },
          title: {
            text: 'Impressions',
            style: {
              color: '#26e7a6',
            },
          },
        },
      ],
    },
  });


  useEffect(() => {
    setChartData({
      ...chartData,
      series: [
        {
          ...chartData.series[0],
          data: revenue,
        },
        {
          ...chartData.series[1],
          data: impressions,
        },
      ],
    });
  }, [chartData, revenue, impressions]);


  return (
    <div className="formBox">
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
      </div>
    </div>
  );
};