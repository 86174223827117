import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  is_active: yup.boolean().required('Please indicate if the website is active'),
  url: yup.string().url('Invalid URL').required('URL is required'),
  index: yup.string().required('Category is required'),
});

export const CreateWebsite = ({ setActiveComponent }) => {
  const [CategoryData, setCategoryData] = useState([]);
  const getdictionary =  async() =>
  {
    try {
      const response = await axios.get('https://popupexamplebackend.dev-ewdtech.org/dict/getcategoris');
     setCategoryData(response.data.data.categories);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
useEffect(() => {
  getdictionary()
}, [])

const CategoryDataArray = Object.values(CategoryData);

  const navigate= useNavigate()
  const redirect=(link)=>{
    navigate(link)
  }
  const [state, setState] = useState({
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const createAdWebsite = async (data) => {
    console.warn(data)
    const is_active = 1;
    const searchValue = data.index;
    const keyOfSearchValue = Object.keys(CategoryData).find(key => CategoryData[key] === searchValue);
    setState(prevState => ({ ...prevState, name: data.name, idcategory: JSON.parse(keyOfSearchValue), is_active: is_active, url: data.url }));
    try {
      const responsesite =  await axios.post('https://popupexamplebackend.dev-ewdtech.org/site/create', {
        idpublisher : JSON.parse(localStorage.getItem('user')), idstatus : 3500 , idcategory : state.idcategory, is_active :is_active , name : state.name, url : state.url
      })
      if(responsesite.data.status===1){
        Swal.fire({
          icon: "success",
          text: "Website Created Successfully!",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `
          }
        });
        navigate('/websites')
      }else{
        Swal.fire({
          icon: "error",
          text: "Failed to Create Website. Try Again!",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutUp
              animate__faster
            `
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error.message,
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutUp
            animate__faster
          `
        }
      });
    }
  };

  return (
    <div className=''>
       
          <p  className='title title_margins'>Add New Website</p>
       
    <form onSubmit={handleSubmit(createAdWebsite)} className="formBox form">
       
        <div className="form-content">
          <div className="form-field">
            <label className='form__label'>Name</label>
            <input
              type="text"
              className="form__input"
              placeholder="Name"
              name="name"
              {...register("name")}
            />
            {errors.name && <p className="error-message">{errors.name.message}</p>}
          </div>

          <div className="form-checkbox">
            <input
              type="checkbox"
              className=""
              checked={state.is_active}
              {...register("is_active")}
            />
            <label>Is Active</label>
          </div>

          {errors.is_active && <p className="error-message">{errors.is_active.message}</p>}

          <div className="form-field">
            <label  className='form__label'>Domain URL</label>
            <input
              type="text"
              className="form__input"
              placeholder="Domain URL"
              name="url"
              {...register("url")}
            />
            {errors.url && <p className="error-message">{errors.url.message}</p>}
          </div>

          <div className="form-field">
            <label  className='form__label'>Category</label>
            <select
              className="form__input"
              name="index"
              {...register("index")}
            >
              <option value="" disabled>Select Category</option>
              {CategoryDataArray.map((value,index) =>{
                  return <option key={index} value={value}>
                  {value}
                </option>
              })}
            </select>
            {errors.idcategory && <p className="error-message">{errors.idcategory.message}</p>}
          </div>

          <div className="form-field hidden">
            <label  className='form__label'>Publisher</label>
            <input
              type="text"
              className="form__input"
              placeholder="Publisher"
              name="idpublisher"
            />
          </div>
        </div>

        <div className="form_btns">
          <button
            type="button"
            className="white_btn"
            onClick={() => redirect('/websites')}
          >
            Cancel
          </button>
          <button type='submit' className="blue_btn">Create Website</button>
        </div>
    </form>
    </div>
  );
};
