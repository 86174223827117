import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import logo from "../../components/assets/images/logo.svg";
import illustration from "../../components/assets/images/illustration.svg";
import { useNavigate } from "react-router-dom";
const validationSchema = yup.object({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const redirect = (link) => {
    navigate(link);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    console.log(data);
    
  };

  return (
    <div className="signup">
      {/* left section */}
      <div className="singup_inner">
      <div className="left_section">
          <div className="logo">
            <img className="logo_Img" src={logo} alt="something" />
            <span className="logo_Name">
              Pop <b>Example</b>
            </span>{" "}
          </div>
          <div className="signup_inner ill">
        <div className="illustration_Container">
        <div className="illustration">
            <img src={illustration} alt="something" />
          </div>
          <p className="left_heading"> A few more clicks to <br/> sign up to your account.</p>
          <p className="left_subHeading">Manage all your e-commerce accounts in one place </p>
        </div>
        </div>
        </div>

        {/* right section  */}
        <div className="signup_inner">
          <form onSubmit={handleSubmit(onSubmit)} className="signup_form ">
            <p className="signup_title title_margins">Send a Reset Token at</p>
            <div className="formText ">
              <p>
                {" "}
                A few more clicks to sign up to your account. Manage all your
                e-commerce accounts in one place{" "}
              </p>
            </div>
            <div className="form-content">
              <div className="form-field">
                <input
                  type="email"
                  className="form__input singup_input"
                  placeholder="Your Email"
                  {...register("email")}
                />
                {errors.email && (
                  <p className="error-message">{errors.email.message}</p>
                )}
              </div>

             

            
            </div>

            <div className="form_btns signup_btns">
              {/* Add your Cancel button here */}
              <button
             
                type="submit"
                className="blue_btn signup_btn"
              >
                Send Token 
              </button>
              <button   onClick={() => {
                  redirect("/login");
                }} type="submit" className="white_btn signup_btn">
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
