// Navbar.js
import React from "react";
import avatar from "../assets/images/avatar-1.jpg";
import "./navbar.css";
import { Link } from "react-router-dom";
import { FaBell } from "react-icons/fa";
const Navbar = () => {
  return (
    <>
      <div className="topbar">
        <div className="toggle container__ ">
          {/* <RxHamburgerMenu size={18} /> */}
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
        </div>

        <div className="user">
          <div>$ 0.00</div>
          {/* <img src={avatar} alt="" /> */}
         <Link className="bell" to='/notifications'>  <FaBell size={20} /></Link>
        
          <div className="dropdown">
            <button className="dropbtn">
              <img src={avatar} alt="" />{" "}
            </button>
            <div className="dropdown-content">
              <div className="tt-dropdown-content">
                <div className="tt-dropdown-triangle"></div>
                <Link to="/profile">Profile</Link>
                <Link to="/profile">Change password</Link>
                <Link to='/notifications'>Notification preferences</Link>
                <Link href="#">Two-factor authentication</Link>
                <Link to="/login">Logout</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
