import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import logo from "../../components/assets/images/logo.svg";
import illustration from "../../components/assets/images/illustration.svg";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
const validationSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().min(6).required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  agreeToTerms: yup
    .boolean()
    .oneOf([true], "Please agree to the terms and policy"),
});

const Signup = () => {
  const [loading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate()
  const location = useLocation();
  const [refParam, setRefParam] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const refValue = queryParams.get('ref');
    if (refValue) {
      setRefParam(refValue);
    }
  }, [location.search]);
  const redirect = (link) => {
    navigate(link)
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit =async (data) => {
const name = data.firstName+data.lastName
const email = data.email
const send_password =1
const allow_login =  0
 const campaigns_post_moderation= 0
const is_active= 1
const idrole= 4 
const password = data.password
const ref= refParam;

const response =  await axios.post('https://popupexamplebackend.dev-ewdtech.org/users/register_user',
{
  name , email, send_password,  allow_login, campaigns_post_moderation, is_active, idrole,ref, password
})
  if(response?.data?.staus===1){
    Swal.fire({
      icon: "success",
      text: "Registration Successfull!",
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `
      }
    });
  }else {
    Swal.fire({
      icon: "error",
      text: "Failed to register. Try Again!",
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutUp
          animate__faster
        `
      }
    });
  }
  };
  const anyFieldHasError =
    errors.firstName || errors.lastName || errors.email || errors.password || errors.confirmPassword || errors.agreeToTerms;

  return (
    <div className="signup">
      <div className="singup_inner">
        {/* left section */}
        <div className="left_section">
          <div className="logo">
            <img className="logo_Img" alt="something" src={logo} />
            <span className="logo_Name">
              Pop <b>Example</b>
            </span>{" "}
          </div>
          <div className="signup_inner ill">
            <div className="illustration_Container">
              <div className="illustration">
                <img alt="something" src={illustration} />
              </div>
              <p className="left_heading"> A few more clicks to <br /> sign up to your account.</p>
              <p className="left_subHeading">Manage all your e-commerce accounts in one place </p>
            </div>
          </div>
        </div>

        {/* right section  */}
        <div className="signup_inner">
          <form onSubmit={handleSubmit(onSubmit)} className="signup_form ">
            <p className="signup_title title_margins">Sign Up</p>
            <div className="formText ">

              <p > A few more clicks to  sign up to your account. Manage all your e-commerce accounts in one place </p>
            </div>
            <div className="form-content">
              <div className="form-field">
                <input
                  type="text"
                  className="form__input singup_input"
                  placeholder="First Name"
                  {...register("firstName")}
                />
              </div>

              <div className="form-field">
                <input
                  type="text"
                  className="form__input singup_input"
                  placeholder="Last Name"
                  {...register("lastName")}
                />
              </div>

              <div className="form-field">
                <input
                  type="text"
                  className="form__input singup_input"
                  placeholder="Email"
                  {...register("email")}
                />
              </div>

              <div className="form-field">
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form__input singup_input"
                    placeholder="Password"
                    {...register("password")}
                  />
                  <span onClick={() => setShowPassword(!showPassword)}>
                  </span>
                </div>
                {errors.password && (
                  <p className="error-message">{errors.password.message}</p>
                )}
              </div>

            <div className="form-field">
              <div className="password-input">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form__input singup_input"
                  placeholder="Confirm Password"
                  {...register("confirmPassword")}
                />
                <span
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                </span>
              </div>
              {errors.confirmPassword && (
                <p className="error-message">
                  {errors.confirmPassword.message}
                </p>
              )}
            </div>
            <div className="form-checkbox">
              <input disabled checked type="checkbox" {...register("idrole")} />
              <label>
               Publisher
              </label>
            </div>
           
            <div className="form-checkbox">
              <input   type="checkbox" {...register("agreeToTerms")} />
              <label>
                I Agree to the <a href="/"> Privacy Policy</a>
              </label>
              
            </div>
            {errors.agreeToTerms && (
              <p className="error-message">{errors.agreeToTerms.message}</p>
            )}
            {anyFieldHasError && <p className="error-message"> Please fill all fields</p>}
          </div>

            <div className="form_btns signup_btns">
              {/* Add your Cancel button here */}
              <button disabled={loading} type="submit" className="blue_btn signup_btn">
                Register
              </button>
              <button disabled={loading} type="button" onClick={() => { redirect("/login") }} className="white_btn signup_btn">
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
