import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../dashboard/Loader';

const Websites = () => {
  const [AllSiteData, setAllSiteData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchAllWebsites = async () => {
    setLoading(true)
    try {
      const params = {
        page: 1,
        sort: 'AUS',
        'per-page': 100,
        id : JSON.parse(localStorage.getItem('user'))
      };
      const response = await axios.get('https://popupexamplebackend.dev-ewdtech.org/site/getAllSites', {
        params: params,
      });
      setAllSiteData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchAllWebsites();
  }, []);

  const navigate = useNavigate();

  const redirect = (link) => {
    navigate(link);
  };

  const data = AllSiteData.map((site) => ({
    id: site.id,
    domain: site.name,
    category: site.category.name,
    status: site.status.name,
  }));
  const columns = [
    {
      name: 'Id',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Domain',
      selector: (row) => row.domain,
      sortable: true,
    },
    {
      name: 'Category',
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div>
          <p className={`${row.status === 'Approved' ? 'activeStatus' : 'inactiveStatus'}`}>
            {row.status}{' '}
          </p>
        </div>
      ),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <button onClick={() => navigate(`/website/verify?idsite=${row.id}`)} className="white_btn">
            Click to verify
          </button>
        </div>
      ),
    },
  ];
  

  return (
    <div>
      <div className='web_heading'>
        <h3 className=''>Websites</h3>
        <button onClick={() => redirect('/websites/create')} className='blue_btn'>
          Add Website
        </button>
      </div>
     {!loading? <DataTable
        columns={columns}
        data={data}
        pagination
        style={{
          fontSize: '16px',
          border: '1px solid #ddd',
          borderRadius: '5px',
          margin: '10px',
        }}
        noHeader
        striped
        highlightOnHove
        pointerOnHover
        onRowClicked={(row) => {
          redirect(`/websites/details?idsite=${row.id}`);
        }}
        customStyles={{
          headRow: {
            style: {
              background: '#f5f5f5',
            },
          },
          headCells: {
            style: {},
          },
          rows: {
            style: {},
          },
        }}
      />:<Loader />}
    </div>
  );
};

export default Websites;
