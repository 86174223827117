import React, { useEffect, useState } from 'react';
import { FaPlus, FaEdit, FaChartBar, FaMap } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from '../dashboard/Loader';
const WebsiteDetails = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const idsite = urlParams.get('idsite');
  const [loading, setLoading] = useState(true);
  const [websiteDetailsData, setWebsiteDetailsData] = useState(null);

  useEffect(() => {
    const fetchWebsiteDetails = async () => {
        console.log(idsite)
      try {
        const response = await axios.post("https://popupexamplebackend.dev-ewdtech.org/site/getSpecificSite", {
          idsite
        });
        const {data}= response.data
//   Object.entries(data).map(([key, value], index) => (console.log(key, value)))
setWebsiteDetailsData(data)
} catch (error) {
        console.error('Error fetching website details:', error);
        setWebsiteDetailsData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchWebsiteDetails();
  }, [idsite]);

  if (loading) {
    return <Loader />;
  }

  if (!websiteDetailsData) {
    return <div>No valid site ID found</div>;
  }
console.log(websiteDetailsData)
  return (
    <div>
      <p className='title title_margins'>Website Details</p>

      <div className='website-details-btn'>
        <Link to='/websites/create' className='blue_btn'>
          <FaPlus className="icon" /> Create
        </Link>
        <Link to={`/websites/update?idsite=${websiteDetailsData.id}`} className='blue_btn'>
          <FaEdit className="icon" /> Update
        </Link>
        <Link to={`/statistics?idsite=${idsite}`} className='blue_btn'>
          <FaChartBar className="icon" /> Statistics
        </Link>
        <Link to={`/websites/zones?idsite=${idsite}`} className='blue_btn'>
          <FaMap className="icon" /> Zones
        </Link>
        <Link to={`/create-zone?idsite=${idsite}`} className='blue_btn'>
          <FaPlus className="icon" /> Zone
        </Link>
      </div>
      <div className="formBox form ">
      { websiteDetailsData &&   <table className="website-details-table table table-striped">
          <tbody>
          
              <tr className='odd-row' >
                <td>Name</td>
                <td>{websiteDetailsData.name}</td>
              </tr>
              <tr  className='even-row' >
                <td>Website URL</td>
                <td>{websiteDetailsData.url}</td>
              </tr>
              <tr className='odd-row' > <td>Category</td>
              <td>{websiteDetailsData.name}</td>
              </tr>
              <tr  className='even-row' >
                 <td>Description</td>
                <td>{websiteDetailsData.des || "--"}</td>
              </tr>
              <tr className='odd-row' > 
                 <td>Zones</td>
                <td>{websiteDetailsData.zones?.map((zone)=> <span> {zone.name}+ ", "</span>) || "--"}</td>
              </tr>
              <tr  className='even-row' >
                 <td>Status</td>
                <td>{websiteDetailsData.status.name}</td>
              </tr>
              <tr className='odd-row' > 
                 <td>Active</td>
                <td>{websiteDetailsData.is_active? "1": "0"}</td>
              </tr>
              <tr  className='even-row' >
                 <td>block_reason</td>
                <td>{websiteDetailsData.block_reason?.name || "--" } </td>
              </tr>
              <tr className='odd-row' > 
                 <td>Created at</td>
                <td>{websiteDetailsData.updated_at || "--"}</td>
              </tr>
              <tr  className='even-row' >
                 <td>Created at</td>
                <td>{websiteDetailsData.created_at || "--" } </td>
              </tr>
          </tbody>
        </table>}
      </div>
    </div>
  );
};

export default WebsiteDetails;
