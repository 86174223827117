import React, {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
const Billing = () => {
  const [isCheckoutOpen, setCheckoutOpen] = useState(false);
  const [transctonData, settransctonData] = useState([]);
  const openCheckoutModal = () => {
    setCheckoutOpen(true);
  };

  const closeCheckoutModal = () => {
    setCheckoutOpen(false);
  };

  const handleCashOut = (formData) => {
    // Handle the cash-out logic here
    console.log(formData);
    closeCheckoutModal();
  };

const gettransctions  =  async()=>
{
  try{
    const params = {
      page: 1,
      sort: 'AUS',
      'per-page': 100,
      id : JSON.parse(localStorage.getItem('user'))
    };
const response  =  await axios.get('https://popupexamplebackend.dev-ewdtech.org/payout/getPayouts', {
  params: params,
})
settransctonData(response.data.data);
console.log(response.data.data);
  }catch(error){
    console.log(error)
  }
}

useEffect(() => {
  gettransctions()
}, [])

const data = transctonData && transctonData.map((item) => {
  const formattedAmount = item.amount.toFixed(2) + "$"; // Format amount with two decimal places and append "$"
  const formattedDescription = item.descr || '--'; // Use a default value if description is null

  return {
    id: formattedAmount,
    domain: formattedDescription,
    category: item.status.name,
    CREATED: item.created_at
  };
});

const columns = [
  {
    name: 'Amount',
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: 'Description',
    selector: (row) => row.domain,
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => row.category, 
    sortable: true,
  },
  {
    name: 'Created',
    selector: (row) => row.CREATED, 
    sortable: true,
  }
];


  return (
    <div>
      <div className='web_heading'>
        <p className='title '>Payouts</p>
        <button onClick={openCheckoutModal} className='blue_btn'>Cash out</button>
      </div>
      <DataTable
        columns={columns}
        data={data}
        pagination 
        style={{
          fontSize: '16px',
          border: '1px solid #ddd',
          borderRadius: '5px',
          margin: '10px',
        }}
        noHeader 
        striped 
        highlightOnHove
        pointerOnHover
        onRowClicked={null}
        customStyles={{
          headRow: {
            style: {
              background: '#f5f5f5', // Customize the table header row
            },
          },
          headCells: {
            style: {
            
            },
          },
          rows: {
            style: {

            },
          },
        }}
      />
        <Checkout isOpen={isCheckoutOpen} onClose={closeCheckoutModal} onSubmit={handleCashOut} />
    </div>
  );
};

export default Billing;
const Checkout = ({ isOpen, onClose, onSubmit }) => {
  const schema = Yup.object().shape({
    amount: Yup.number().typeError("Invalid Value").required('Amount is required'),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <div className={`modal ${isOpen ? 'show' : 'hide'}`}>
    
      <div className="modal-content">
        <div className='modal_header'>
        <p className='title  '>Withdraw Fund</p>
        <span className="close" onClick={onClose}>&times;</span>
        </div>
      
        <form className='form-content form_padding ' onSubmit={handleSubmit(onSubmit)}>
          <div className="cash_input">
            <label htmlFor="amount">Amount</label>
            <input
            className='form__input'
              type="number"
              {...register('amount')}
              placeholder="Amount"
            />
            <p className="error">{errors.amount?.message}</p>
          </div>
          <div className="form-group">
            <button className='cash_btn blue_btn' type="submit">Cash Out</button>
          </div>
        </form>
      </div>
    </div>
  );
};
