import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Loader from '../dashboard/Loader';

const Zones = () => {
  const [zones, setallZones] = useState([]);
  const [loading] = useState(false);
;
const getzones = async (data) => {
  const urlParams = new URLSearchParams(window.location.search);
  const idsite = urlParams.get('idsite');   
   data = {idsite }
    try {
      const responsesite = await axios.post('https://popupexamplebackend.dev-ewdtech.org/zone/getzoneslist', data)
      console.log(responsesite.data)
      if(responsesite.data.status===1){
setallZones(responsesite.data.data)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const data = zones.map((zone) => ({
    id:zone.id,
    Name: zone.name,
    Format: zone.format.name,
    Dimension: zone.dimension || '--'    ,
    Active: zone.is_active,
    Created:zone.created_at
  }));
useEffect(()=>{getzones()}, [])
  const columns = [
    {
      name: 'Name',
      selector: (row)=>row.Name,
      sortable: true,
    },
    {
      name: 'Domain',
      selector: (row)=>row.Format,
      sortable: true,
    },
    {
      name: 'Dimension',
      selector:(row)=> row.Dimension,
      sortable: true,
    },
      {
        name: 'Created',
        selector: (row)=>row.Created,
        sortable: true,
      },
      {
        name: 'Active',
        selector: (row)=>row.Active,
        sortable: true,
        cell: (row) => (
          <div>
        
            <p className={`${row.status==='Active'? "activeStatus":"inactiveStatus"}`}>{row.Active?"Yes":"No"} </p>
          </div>
        ),
        
      },
   
  ];


 
  return (
    <div>
      <div className='web_heading'>
        <p className='title'>Zones List</p>
       
      </div>
     {!loading? <DataTable
        columns={columns}
        data={data}
        pagination
        style={{
          fontSize: '16px',
          border: '1px solid #ddd',
          borderRadius: '5px',
          margin: '10px',
        }}
        noHeader
        striped
        highlightOnHove
        pointerOnHover
        onRowClicked={(row) =>null}
        customStyles={{
          headRow: {
            style: {
              background: '#f5f5f5',
            },
          },
          headCells: {
            style: {},
          },
          rows: {
            style: {},
          },
        }}
      />:<Loader />}
    </div>
  );
};

export default Zones;
