import React, { useRef, useState } from "react";

const CopyReferrals = ({ height,referalLinks }) => {
  const inputRef = useRef(null);
  const [inputValue] = useState( referalLinks);
  const [copytext_, setCopytext_] = useState("Copy");

  const copyText = (event) => {
    // Select the text in the input
    inputRef.current.select();
    inputRef.current.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text to the clipboard
    document.execCommand("copy");
    setCopytext_("Copied");
    // Deselect the input
    inputRef.current.setSelectionRange(0, 0);
  };
  return (
    <React.Fragment>
      <div className="tt-copied-box">
        <textarea
          style={{ minHeight: height }}
          className="w-100 p-3 non-resizable-textarea border rounded"
          readOnly
          ref={inputRef}
          value={inputValue}
        ></textarea>
      </div>
      <button onClick={copyText} className="tt-copy-button px-3 py-2 mt-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-clipboard"
          viewBox="0 0 16 16"
        >
          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
        </svg>{" "}
        {copytext_}
      </button>
    </React.Fragment>
  );
};

export default CopyReferrals;
