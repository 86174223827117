import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../dashboard/Loader';
import Swal from 'sweetalert2';

const UpdateWebsite = ({ setActiveComponent }) => {
  const [CategoryData, setCategoryData] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const idsite = urlParams.get('idsite');
  const [loading, setLoading] = useState(true);
  const [websiteDetailsData, setWebsiteDetailsData] = useState(null);
  const getdictionary = async () => {
    try {
      const response = await axios.get('https://popupexamplebackend.dev-ewdtech.org/dict/getcategoris');
      setCategoryData(response.data.data.categories);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getdictionary();
  }, []);
  useEffect(() => {
    const fetchWebsiteDetails = async () => {
      console.log(idsite);
      try {
        const response = await axios.post(`https://popupexamplebackend.dev-ewdtech.org/site/getSpecificSite`, {
          idsite,
        });
        const { data } = response.data;
        setWebsiteDetailsData(data);
      } catch (error) {
        console.error('Error fetching website details:', error);
        setWebsiteDetailsData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchWebsiteDetails();
  }, [idsite]);

  const navigate = useNavigate();
  const redirect = (link) => {
    navigate(link);
  };
  const [state, setState] = useState({});
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: websiteDetailsData,
  });
  
  // Set nested values separately
  useEffect(() => {
    if (websiteDetailsData) {
      setValue('name', websiteDetailsData.name);
      setValue('url', websiteDetailsData.url);
      setValue('is_active', websiteDetailsData.is_active);
      websiteDetailsData.zones.forEach((zone, index) => {
        setValue(`zones[${index}].name`, zone.name);
      });
    }
  }, [setValue, websiteDetailsData]);

  const createAdWebsite = async (data) => {
    const is_active = data.is_active ? 1 : 0;
    const searchValue = data.index;
    const keyOfSearchValue = Object.keys(CategoryData).find(key => CategoryData[key] === searchValue);
    setState(prevState => ({ ...prevState, name: data.name, idcategory:keyOfSearchValue, is_active: is_active, url: data.url }));
    try {
      const responsesite =  await axios.post('https://popupexamplebackend.dev-ewdtech.org/site/updateSite', {
       idstatus: 3510, idblockreason: "1", idcategory: state.idcategory, is_active: is_active, name: state.name, url: state.url, idsite : idsite
      });
      if(responsesite.data.status===1){
        Swal.fire({
          icon: "success",
          text: "Website Updated Successfully!",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `
          }
        });
        navigate('/websites')
      }else{
        Swal.fire({
          icon: "error",
          text: "Failed to Update Website. Try Again!",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutUp
              animate__faster
            `
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error.message,
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutUp
            animate__faster
          `
        }
      });
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!websiteDetailsData) {
    return <div>No valid site ID found</div>;
  }

 

  const CategoryDataArray = Object.values(CategoryData);

  return (
    <div className=''>
      <p className='title title_margins'>Update Website</p>
      <form onSubmit={handleSubmit(createAdWebsite)} className="formBox form">
        <div className="form-content">
          <div className="form-field">
            <label className='form__label'>Name</label>
            <input
              type="text"
              className="form__input"
              placeholder="Name"
              name="name"
              {...register("name")}
            />
            {errors.name && <p className="error-message">{errors.name.message}</p>}
          </div>

          <div className="form-checkbox">
            <input
              type="checkbox"
              className=""
              checked={state.is_active}
              {...register("is_active")}
            />
            <label>Is Active</label>
          </div>

          {errors.is_active && <p className="error-message">{errors.is_active.message}</p>}

          <div className="form-field">
            <label className='form__label'>Domain URL</label>
            <input
              type="text"
              className="form__input"
              placeholder="Domain URL"
              name="url"
              {...register("url")}
            />
            {errors.url && <p className="error-message">{errors.url.message}</p>}
          </div>

          <div className="form-field">
            <label className='form__label'>Category</label>
            <select
              className="form__input"
              name="index"
              {...register("index")}
            >
              <option value="" disabled>Select Category</option>
              {CategoryDataArray.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
            {errors.idcategory && <p className="error-message">{errors.idcategory.message}</p>}
          </div>

          <div className="form-field hidden">
            <label className='form__label'>Publisher</label>
            <input
              type="text"
              className="form__input"
              placeholder="Publisher"
              name="idpublisher"
            />
          </div>
        </div>

        <div className="form_btns">
          <button
            type="button"
            className="white_btn"
            onClick={() => redirect('/websites')}
          >
            Cancel
          </button>
          <button type='submit' className="blue_btn">Update</button>
        </div>
      </form>
    </div>
  );
};

export default UpdateWebsite;
