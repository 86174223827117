import React from 'react';
import {ThreeDots} from 'react-loader-spinner';

const Loader = ({height}) => {
  return (
    <div style={{height:height}} className="loader-wrapper">
      <ThreeDots
        type="ThreeDots"
        height={80}
        width={80}
        radius={9}
        color="#2449b0" // Set your desired color (blue in this case)
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
};

export default Loader;
